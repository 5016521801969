@import 'node_modules/monkey-style-guide/assets/scss/partials/variables';

.breadcrumb {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #4b4a53;
  flex-wrap: wrap;

  .current {
    text-decoration-line: underline;
    color: map-get($mecx-theme, main);
  }
}
