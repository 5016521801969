@import 'assets/scss/theme';
@import 'node_modules/monkey-style-guide/assets/scss/theme';
@import '@angular/cdk/overlay-prebuilt.css';

monkey-toggle mecx-monkey-toggle .slider-false:before {
  bottom: 3.5px !important;
}

monkey-toggle mecx-monkey-toggle .slider-true:before {
  left: 2px !important;
  bottom: 3.5px !important;
}

.monkeyecx-tooltip {
  background: #474747;
  border-radius: 4px;
  color: #f8f8f2;
  font-size: 14px;
  opacity: 0;
  padding: 3px 8px;
  position: absolute;
  text-align: center;
  width: 150px;
  z-index: 1000;
}

.monkeyecx-tooltip-title {
  align-items: center;
  color: #fafafa;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: 16px;
  padding: 8px 0;
  text-align: center;
}

.monkeyecx-tooltip-description {
  align-items: center;
  border-top: 1px solid #fafafa;
  color: #fafafa;
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  justify-content: center;
  line-height: 14px;
  padding: 8px 0;
  text-align: center;
}

.monkeyecx-tooltip:after {
  border-style: solid;
  content: '';
  position: absolute;
}

.monkeyecx-tooltip-top:after {
  border-color: #474747 transparent transparent transparent;
  border-width: 5px;
  left: 50%;
  margin-left: -5px;
  top: 100%;
}

.monkeyecx-tooltip-bottom:after {
  border-color: transparent transparent #474747 transparent;
  border-width: 5px;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
}

.monkeyecx-tooltip-left:after {
  border-color: transparent transparent transparent #474747;
  border-width: 5px;
  left: 100%;
  margin-top: -5px;
  top: 50%;
}

.monkeyecx-tooltip-right:after {
  border-color: transparent #474747 transparent transparent;
  border-width: 5px;
  margin-top: -5px;
  right: 100%;
  top: 50%;
}

.monkeyecx-tooltip-show {
  opacity: 1;
}

.monkeyecx-credentials-button {
  border-radius: 20px;
  background-color: var(--mecx-color-theme-main);
  color: var(--mecx-color-theme-contrast-main);
  position: absolute;
  bottom: 10px;
  right: 10px;
  z-index: 99999;
  padding: 12px;
  cursor: pointer;
}

.monkeyecx-credentials-button:hover {
  opacity: 0.8;
}

.monkeyecx-credentials-button:active {
  box-shadow: 0 2px #666;
  transform: translateY(1px);
}

.json-key {
  color: #a32a2a;
}

.json-value {
  color: #010180;
}

.json-string {
  color: #818103;
}

.mk-i {
  line-height: 1;
  font-size: 1rem;
  display: grid;
}

monkeyecx-alerts {
  .body {
    .alert-body {
      .message {
        white-space: pre-line !important;
      }
    }
  }
}
