.monkey-tooltip {
  background: #474747;
  border-radius: 4px;
  color: #f8f8f2;
  font-size: 14px;
  opacity: 0;
  padding: 3px 8px;
  position: absolute;
  text-align: center;
  width: 150px;
  z-index: 1000;
}

.monkey-tooltip-title {
  align-items: center;
  color: #fafafa;
  display: flex;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  justify-content: center;
  line-height: 16px;
  padding: 8px 0;
  text-align: center;
}

.monkey-tooltip-description {
  align-items: center;
  border-top: 1px solid #fafafa;
  color: #fafafa;
  display: flex;
  font-size: 12px;
  font-style: normal;
  font-weight: normal;
  justify-content: center;
  line-height: 14px;
  padding: 8px 0;
  text-align: center;
}

.monkey-tooltip:after {
  border-style: solid;
  content: '';
  position: absolute;
}

.monkey-tooltip-top:after {
  border-color: #474747 transparent transparent transparent;
  border-width: 5px;
  left: 50%;
  margin-left: -5px;
  top: 100%;
}

.monkey-tooltip-bottom:after {
  border-color: transparent transparent #474747 transparent;
  border-width: 5px;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
}

.monkey-tooltip-left:after {
  border-color: transparent transparent transparent #474747;
  border-width: 5px;
  left: 100%;
  margin-top: -5px;
  top: 50%;
}

.monkey-tooltip-right:after {
  border-color: transparent #474747 transparent transparent;
  border-width: 5px;
  margin-top: -5px;
  right: 100%;
  top: 50%;
}

.monkey-tooltip-show {
  opacity: 1;
}
