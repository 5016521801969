@import './colors';

$border-sizes: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
);
$border-radius: (
  xxs: 2px,
  xs: 4px,
  sm: 8px,
  md: 14px,
  lg: 16px,
  xl: 24px,
  circle: 50%,
  pill: 50rem
);

.border {
  border: 1px solid;

  @each $size, $value in $border-sizes {
    &-#{$size} {
      border-width: $value !important;
    }
  }

  @each $size, $value in $border-radius {
    &-radius-#{$size} {
      border-radius: $value !important;
    }
  }

  @include theme-colors('border-color');
  @include gray-colors('border-color');
  @include error-colors('border-color');
  @include warning-colors('border-color');
  @include success-colors('border-color');
  @include question-colors('border-color');
}
