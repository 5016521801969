span:empty {
  height: 24px;
}

.loading:empty {
  margin-top: 4px;
  height: 24px;
  width: 100%;
}

.pie-chart:empty {
  width: 130px;
  height: 130px;
  border-radius: 108px;
}

.filter-option:empty {
  height: 48px;
  border-radius: 8px;
}

.card:empty,
.header:empty,
.description:empty,
.footer:empty,
.body:empty,
.loading:empty,
span:empty,
.h2:empty,
.filter-option,
.pie-chart {
  animation: loading 1.1s infinite linear;
  background: #ebebeb;
  background-image: -webkit-linear-gradient(
    to right,
    #ebebeb 0%,
    #f2f3f5 20%,
    #ebebeb 40%,
    #ebebeb 100%
  );
  background-image: linear-gradient(to right, #ebebeb 0%, #f2f3f5 20%, #ebebeb 40%, #ebebeb 100%);
  background-repeat: no-repeat;
}
@keyframes loading {
  0% {
    background-position: -100px;
  }
  100% {
    background-position: 500px;
  }
}
