@mixin page-layouts-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  .page-layout {
    &.carded {
      &.fullwidth {
        > .center {
          > .content-card {
            background: map-get($background, card);
            border-radius: 8px 8px 0 0;

            > .toolbar {
              border-bottom-color: map-get($foreground, divider);
            }
          }
        }
      }

      &.left-sidebar,
      &.right-sidebar {
        > .center {
          > .content-card {
            background: map-get($background, card);
            border-radius: 8px 8px 0 0;

            > .toolbar {
              border-bottom-color: map-get($foreground, divider);
            }
          }
        }
      }
    }

    &.simple {
      &.left-sidebar,
      &.right-sidebar {
        > .center {
          > .content {
            background: map-get($background, card);
          }
        }

        &.inner-sidebar {
          > .content {
            > .center {
              > .content {
                background: map-get($background, card);
              }
            }
          }
        }
      }
    }
  }
}

$carded-header-height: 135px !default;
$carded-toolbar-height: 64px !default;
$header-height: 120px !default;

$carded-header-height-without-toolbar: $carded-header-height - $carded-toolbar-height;

.page-layout {
  position: relative;
  overflow: hidden;

  &.carded {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
    min-width: 100%;

    > .top-bg {
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      left: 0;
      height: $carded-header-height;
    }

    &.fullwidth {
      > .center {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        position: relative;
        z-index: 2;
        padding: 0 32px;
        width: 100%;
        min-width: 0;
        max-width: 100%;
        height: 100%;
        max-height: 100%;

        > .header {
          height: $carded-header-height-without-toolbar !important;
          min-height: $carded-header-height-without-toolbar !important;
          max-height: $carded-header-height-without-toolbar !important;
        }

        > .content-card {
          display: flex;
          flex-direction: column;
          flex: 1 0 auto;
          overflow: hidden;

          > .toolbar {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            border-bottom: 1px solid;
            height: $carded-toolbar-height;
            min-height: $carded-toolbar-height;
            max-height: $carded-toolbar-height;
          }

          > .content {
            flex: 1 0 auto;
          }
        }
      }

      &.tabbed {
        > .center {
          width: 100%;
          min-width: 0;

          > .header {
            flex: 1 1 auto;
          }

          > .content-card {
            > .content {
              display: flex;
            }
          }
        }
      }

      &.inner-scroll {
        flex: 1 1 auto;

        > .center {
          flex: 1 1 auto;

          > .content-card {
            flex: 1 1 auto;

            > .content {
              flex: 1 1 auto;
              overflow: auto;
              -webkit-overflow-scrolling: touch;
            }
          }
        }
      }
    }

    &.left-sidebar,
    &.right-sidebar {
      flex-direction: row;

      > .sidebar {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: 240px;
        min-width: 240px;
        max-width: 240px;
        height: auto;
        overflow: hidden;

        &.locked-open {
          background: none;
          box-shadow: none;

          + .center {
            z-index: 1001;
          }

          &.left-positioned {
            + .center {
              margin-left: 0;
            }
          }

          &.right-positioned {
            + .center {
              margin-right: 0;
            }
          }
        }

        .header {
          height: $carded-header-height;
          min-height: $carded-header-height;
          max-height: $carded-header-height;
        }

        .content {
          background: transparent;
          flex: 1 1 auto;
        }
      }

      > .center {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        position: relative;
        z-index: 3;
        margin-left: 32px;
        margin-right: 32px;
        width: 100%;
        min-width: 0;

        > .header {
          display: flex;
          height: $carded-header-height-without-toolbar;
          min-height: $carded-header-height-without-toolbar;
          max-height: $carded-header-height-without-toolbar;
        }

        > .content-card {
          display: flex;
          flex-direction: column;
          flex: 1 1 auto;
          overflow: hidden;

          > .toolbar {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex: 1 1 auto;
            border-bottom: 1px solid;
            height: $carded-toolbar-height;
            min-height: $carded-toolbar-height;
            max-height: $carded-toolbar-height;

            .sidebar-toggle {
              margin: 0 8px 0 0 !important;
              padding: 0 !important;
              border-radius: 0;
            }
          }

          > .content {
            flex: 1 1 auto;
          }
        }
      }

      &.tabbed {
        > .center {
          width: calc(100% - 32px);
          min-width: 0;

          @include media-breakpoint('lt-lg') {
            width: calc(100% - 64px);
          }

          > .header {
            flex: 1 1 auto;
          }

          > .content-card {
            > .content {
              display: flex;
            }
          }
        }
      }

      &.inner-scroll {
        flex: 1 1 auto;

        > .sidebar {
          .content {
            overflow: auto;
            -webkit-overflow-scrolling: touch;
          }
        }

        > .center {
          flex: 1 1 auto;

          > .content-card {
            flex: 1 1 auto;

            > .content {
              flex: 1 1 auto;
              overflow: auto;
              -webkit-overflow-scrolling: touch;
            }
          }
        }
      }
    }

    &.right-sidebar {
      > .sidebar {
        order: 2;
      }

      > .center {
        order: 1;
      }
    }
  }

  &.simple {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    width: 100%;
    min-width: 100%;

    &.fullwidth {
      > .content {
        flex: 1 1 auto;
        min-width: 0;
      }
    }

    &.fullwidth,
    &.inner-sidebar {
      > .header {
        height: $header-height;
        min-height: $header-height;
        max-height: $header-height;
      }
    }

    &.left-sidebar,
    &.right-sidebar {
      flex-direction: row;

      > .sidebar {
        width: 240px;
        min-width: 240px;
        max-width: 240px;
        overflow: hidden;

        &.locked-open {
          background: none;
          box-shadow: none;

          + .center {
            z-index: 1001;
          }

          &.left-positioned {
            + .center {
              margin-left: 0;
            }
          }

          &.right-positioned {
            + .center {
              margin-right: 0;
            }
          }
        }

        .content {
          flex: 1 1 auto;
        }
      }

      > .center {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        z-index: 3;
        min-width: 0;
        width: 100%;

        > .header {
          height: $header-height;
          min-height: $header-height;
          max-height: $header-height;
        }

        > .content {
          flex: 1 0 auto;
        }
      }

      &.inner-scroll {
        flex: 1 1 auto;

        > .sidebar {
          .content {
            overflow: auto;
            -webkit-overflow-scrolling: touch;
          }
        }

        > .center {
          flex: 1 1 auto;
          overflow: auto;
          -webkit-overflow-scrolling: touch;
        }
      }

      &.inner-sidebar {
        flex-direction: column;
        overflow: hidden;
        height: 100%;

        > .content {
          display: flex;
          min-height: 0;

          > .sidebar {
            &.locked-open {
              background: none;
              box-shadow: none;
            }

            .content {
              overflow: auto;
              -webkit-overflow-scrolling: touch;
            }
          }

          > .center {
            flex: 1 1 auto;
            overflow: auto;
            -webkit-overflow-scrolling: touch;

            > .content {
              border-radius: 8px;
            }
          }
        }
      }
    }

    &.right-sidebar {
      > .sidebar {
        order: 2;
      }

      > .center {
        order: 1;
      }

      &.inner-sidebar {
        > .content {
          > .sidebar {
            order: 2;
          }

          > .center {
            order: 1;
          }
        }
      }
    }
  }

  &.blank {
    width: 100%;
    min-height: 100%;
  }

  @include media-breakpoint('xs') {
    &.carded {
      &.fullwidth {
        > .center {
          padding: 0 16px;
        }
      }

      &.left-sidebar,
      &.right-sidebar {
        > .center {
          margin: 0 16px;
        }
      }
    }
  }
}
