$font-sizes: (
  xxs: 14px,
  xs: 16px,
  sm: 18px,
  md: 20px,
  lg: 24px,
  xl: 32px,
  xxl: 40px
);

.font-size {
  @each $size, $value in $font-sizes {
    &-#{$size} {
      font-size: $value !important;
    }
  }
}

$text-font-settings: (
  gray: #545a63 14px 400 24px 0.5px,
  red: #ff0100 14px 400 24px 0.5px,
  green: #00875a 14px 400 24px 0.5px,
  black: #1f2024 14px 400 24px 0.42px,
  orange: #ecb237 14px 400 24px 0.42px,
  question: map-get($mecx-question, main) 14px 400 24px 0.42px
);

@each $color, $settings in $text-font-settings {
  $class-name: unquote('.text-normal-#{$color}');

  #{$class-name} {
    color: nth($settings, 1);
    font-size: nth($settings, 2);
    font-weight: nth($settings, 3);
    line-height: nth($settings, 4);
    letter-spacing: nth($settings, 5);
  }
}

.text-primary {
  color: map-get($mecx-theme, main);
}

.font-weight {
  &-bold {
    font-weight: 700;
  }

  &-medium {
    font-weight: 500;
  }

  &-regular {
    font-weight: 400;
  }
}

.title-h2 {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  color: #1f2024;
}

.text-center {
  text-align: center;
}

.title-muted {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.03em;
  color: #545a63;
}

.title-data {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  color: #1f2024;
}

.link {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  color: #1f2024;
  text-decoration: underline;

  &:hover {
    cursor: pointer;
  }
}
