.skeleton {
  animation: skeleton-loading 1s linear infinite alternate;
}

@keyframes skeleton-loading {
  0% {
    background-color: #d1dbe0;
  }

  100% {
    background-color: #f0f3f5;
  }
}

.text-loading {
  @extend .skeleton;

  width: 100%;
  height: 12px;
  margin-bottom: 8px;
  border-radius: 8px;
}

.input-loading {
  @extend .skeleton;

  width: 100%;
  height: 48px;
  margin-bottom: 8px;
  border-radius: 8px;
}
