@import 'node_modules/monkey-style-guide/assets/scss/partials/variables';

@mixin gray-colors($attribute: 'color') {
  @each $key, $value in $mecx-gray {
    &-gray-#{$key} {
      #{$attribute}: $value !important;
    }
  }
}

@mixin theme-colors($attribute: 'color') {
  @each $key, $value in $mecx-theme {
    @if type-of($value) != 'map' {
      &-theme-#{$key} {
        #{$attribute}: $value !important;
      }
    }
  }
}

@mixin warning-colors($attribute: 'color') {
  @each $key, $value in $mecx-warning {
    @if type-of($value) != 'map' {
      &-warning-#{$key} {
        #{$attribute}: $value !important;
      }
    }
  }
}

@mixin success-colors($attribute: 'color') {
  @each $key, $value in $mecx-success {
    @if type-of($value) != 'map' {
      &-success-#{$key} {
        #{$attribute}: $value !important;
      }
    }
  }
}

@mixin error-colors($attribute: 'color') {
  @each $key, $value in $mecx-error {
    @if type-of($value) != 'map' {
      &-error-#{$key} {
        #{$attribute}: $value !important;
      }
    }
  }
}

@mixin question-colors($attribute: 'color') {
  @each $key, $value in $mecx-question {
    @if type-of($value) != 'map' {
      &-question-#{$key} {
        #{$attribute}: $value !important;
      }
    }
  }
}

.text {
  @include gray-colors;
  @include theme-colors;
  @include error-colors;
  @include warning-colors;
  @include success-colors;
  @include question-colors;
}

.bg {
  @include gray-colors('background-color');
  @include error-colors('background-color');
  @include warning-colors('background-color');
  @include success-colors('background-color');
  @include question-colors('background-color');

  &-white {
    background-color: #fff;
  }
}

html {
  --mecx-color-error-100: #ffecec !important;
  --mecx-color-warning-100: #fff8eb !important;
}
